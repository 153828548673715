import React from 'react';
import PropTypes from 'prop-types';

import { VAT, VATFIELDS } from '../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../bi/constants/components';

import Input from '../../../../../components/input';

import styles from '../../../styles/form.module.scss';

const Vat = ({
  isFundsForm,
  ticket: {
    VatDetails,
  },
  airline: {
    VatDetails: airlineVatDetails,
  },
  isEditing,
  keyItem,
  handleChangeVatDetails,
}) => {
  const renderVatInputWrapper = (element, isColumn = false) => {
    if (isColumn) {
      return (
        <div className={ styles.row }>
          <div className={ styles['col-1'] }>
            { element }
          </div>
        </div>
      );
    }

    return (
      <div className={ styles['col-1-4'] }>
        { element }
      </div>
    );
  };

  const disabled = isEditing && !isFundsForm;
  const vatDetailsToShow = disabled ? airlineVatDetails : VatDetails;

  const filteredVatArray = vatDetailsToShow.filter(({ Rate }) => !!Rate);

  const inputsArr = filteredVatArray.reduce((newArr, { Rate, BaseVat, Amount }, index, array) => {
    if (index % 2 === 1) return newArr;

    const vatData = array[index + 1];

    const nextInputBaseHtml = vatData ? (
      <Input
        type={ COMPONENTS.INPUT.TYPE.NUMBER }
        disabled={ disabled }
        field={ VATFIELDS.BASE }
        value={ vatData.BaseVat }
        label={ `База НДС ${vatData.Rate}%` }
        onChange={ ({ target: { value } }) => handleChangeVatDetails(keyItem, vatData.Rate, VAT.BASE, value) }
      />
    ) : null;

    const nextInputBaseHtmlWrapper = renderVatInputWrapper(nextInputBaseHtml, isFundsForm);

    const nextInputAmountHtml = vatData ? (
      <Input
        type={ COMPONENTS.INPUT.TYPE.NUMBER }
        disabled={ disabled }
        field={ VATFIELDS.AMOUNT }
        value={ vatData.Amount }
        label={ `Сумма НДС ${vatData.Rate}%` }
        onChange={ ({ target: { value } }) => handleChangeVatDetails(keyItem, vatData.Rate, VAT.AMOUNT, value) }
      />
    ) : null;

    const nextInputAmountHtmlWrapper = renderVatInputWrapper(nextInputAmountHtml, isFundsForm);

    const inputAmountHtml = Rate > 0 && (
      <Input
        type={ COMPONENTS.INPUT.TYPE.NUMBER }
        disabled={ disabled }
        field={ VATFIELDS.AMOUNT }
        value={ Amount }
        label={ `Сумма НДС ${Rate}%` }
        onChange={ ({ target: { value } }) => handleChangeVatDetails(keyItem, Rate, VAT.AMOUNT, value) }
      />
    );

    const inputAmountHtmlWrapper = renderVatInputWrapper(inputAmountHtml, isFundsForm);

    const inputsHtml = (
      <div className={ styles.row } key={ index }>
        { inputAmountHtmlWrapper }
        { nextInputBaseHtmlWrapper }
        { nextInputAmountHtmlWrapper }
      </div>
    );

    return [...newArr, inputsHtml];
  }, []);

  return (
    <div className={ styles.row }>
      { inputsArr }
    </div>
  );
};

Vat.propTypes = {
  isFundsForm: PropTypes.bool,
  isEditing: PropTypes.bool,
  ticket: PropTypes.object.isRequired,
  airline: PropTypes.object.isRequired,
  keyItem: PropTypes.number.isRequired,
  handleChangeVatDetails: PropTypes.func.isRequired,
};

Vat.defaultProps = {
  isFundsForm: false,
  isEditing: false,
};

export default Vat;
