import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../../styles/form.module.scss';

const ProviderInfoSelect = ({
  providerInfo,
  providerName,
  labelSelect,
  field,
  infoType,
  airProviderSelectorFunc,
  validationField,
  k,
  selectLabel,
  handleChangeInput,
  classNameValid,
}) => {
  const info = providerInfo ? providerInfo[infoType] : '';

  const infoForSelect = airProviderSelectorFunc(providerName);

  const optionsSelector = infoForSelect.map(({ value, label }, providerKey) => (
    <option key={ `${providerKey}_name` } value={ value }>
      { label }
    </option>
  ));

  return (
    <div>
      <div className={ `${styles.select_officeId} ${classNameValid || null}` }>
        <label>{ labelSelect }</label>
        <select
          value={ info || '' }
          onChange={ e => handleChangeInput(
            k,
            null,
            null,
            e,
            field,
            e.target.value,
          ) }
        >
          <option key={ '' } value={ '' } disabled>
            { selectLabel }
          </option>
          { optionsSelector }
        </select>
      </div>
      { validationField && (
        <div className={ `${styles['error-msg-company']} error-msg` }>
          { validationField }
        </div>
      )}
    </div>
  );
};

ProviderInfoSelect.propTypes = {
  providerInfo: PropTypes.object.isRequired,
  providerName: PropTypes.string.isRequired,
  labelSelect: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  infoType: PropTypes.string.isRequired,
  airProviderSelectorFunc: PropTypes.func.isRequired,
  validationField: PropTypes.string,
  k: PropTypes.number.isRequired,
  selectLabel: PropTypes.string.isRequired,
  handleChangeInput: PropTypes.func.isRequired,
  classNameValid: PropTypes.string,
};

ProviderInfoSelect.defaultProps = {
  validationField: null,
  classNameValid: '',
};

export default ProviderInfoSelect;
