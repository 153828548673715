import React from 'react';
import PropTypes from 'prop-types';

import { PRICEDETAILS, PRICELABELS } from '../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../bi/constants/components';

import Input from '../../../../../components/input';

import styles from '../../../styles/form.module.scss';

const Price = ({
  isEditing,
  isCancelled,
  priceDetails,
  validation,
  onChangeInput,
  onBlurInput,
  showTaxes,
  showTaxesClue,
}) => {
  const { Base, Commission, Fee, Taxes, Tax, TaxesClue } = priceDetails;

  const colStyle = showTaxes ? styles['col-1-5'] : styles['col-1-4'];

  const clueHtml = showTaxesClue && TaxesClue ? PRICELABELS.TAXES_SMARTWAY_CLUE(TaxesClue) : '';

  const renderInputTaxes = showTaxes ? (
    <div className={ colStyle }>
      <Input
        type={ COMPONENTS.INPUT.TYPE.NUMBER }
        disabled={ isEditing }
        field={ PRICEDETAILS.TAXES }
        value={ Taxes }
        label={ PRICELABELS.TAXES_SMARTWAY }
        onChange={ onChangeInput }
        clue={ clueHtml }
      />
    </div>
  ) : null;

  const handleCalculateTaxes = (value) => {
    const taxValue = (value * 0.01).toFixed(2);

    onChangeInput(null, PRICEDETAILS.TAXES_CLUE, taxValue);
  };

  const handleChangeBase = (e, inputField, value) => {
    onChangeInput(e, inputField, value);

    handleCalculateTaxes(value + Tax);
  };

  const handleChangeTax = (e, inputField, value) => {
    onChangeInput(e, inputField, value);

    handleCalculateTaxes(Base + value);
  };

  return (
    <div className={ `${isCancelled ? styles.col : styles.row}` }>
      <div className={ colStyle }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          disabled={ isEditing }
          field={ PRICEDETAILS.BASE }
          value={ Base }
          label={ PRICELABELS.AVIABASE }
          onChange={ handleChangeBase }
          onBlur={ onBlurInput }
          valid={ validation ? validation.PriceDetails.Base : '' }
        />
      </div>
      <div className={ colStyle }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          disabled={ isEditing }
          field={ PRICEDETAILS.COMMISSION }
          value={ Commission }
          label={ PRICELABELS.COMMISSION }
          onChange={ onChangeInput }
        />
      </div>
      <div className={ colStyle }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          disabled={ isEditing }
          field={ PRICEDETAILS.FEE }
          value={ Fee }
          label={ PRICELABELS.AVIAFEE }
          onChange={ onChangeInput }
        />
      </div>
      <div className={ colStyle }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          disabled={ isEditing }
          field={ PRICEDETAILS.TAX }
          value={ Tax }
          label={ PRICELABELS.AVIATAX }
          onChange={ handleChangeTax }
        />
      </div>
      { renderInputTaxes }
    </div>
  );
};

Price.propTypes = {
  isEditing: PropTypes.bool,
  isCancelled: PropTypes.bool,
  priceDetails: PropTypes.object.isRequired,
  validation: PropTypes.object,
  onChangeInput: PropTypes.func.isRequired,
  onBlurInput: PropTypes.func,
  showTaxes: PropTypes.bool,
  showTaxesClue: PropTypes.bool,
};

Price.defaultProps = {
  isEditing: false,
  isCancelled: false,
  showTaxes: false,
  showTaxesClue: false,
  validation: null,
  onBlurInput: () => {},
};

export default Price;
